import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-hialeah-florida.png'
import image0 from "../../images/cities/scale-model-of-wingman-adventures-in-hialeah-florida.png"
import image1 from "../../images/cities/scale-model-of-sewell-park-in-hialeah-florida.png"
import image2 from "../../images/cities/scale-model-of-spring-garden-point-park-in-hialeah-florida.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Hialeah'
            state='Florida'
            image={image}
            lat='25.8699'
            lon='-80.3029'
            attractions={ [{"name": "Wingman Adventures", "vicinity": "5000 NW 36th St, Miami", "types": ["point_of_interest", "establishment"], "lat": 25.8079321, "lng": -80.27731130000001}, {"name": "Sewell Park", "vicinity": "1801 NW S River Dr, Miami", "types": ["park", "point_of_interest", "establishment"], "lat": 25.7851453, "lng": -80.22411449999998}, {"name": "Spring Garden Point Park", "vicinity": "601 NW 7th Street Rd, Miami", "types": ["park", "point_of_interest", "establishment"], "lat": 25.7793292, "lng": -80.20763199999999}] }
            attractionImages={ {"Wingman Adventures":image0,"Sewell Park":image1,"Spring Garden Point Park":image2,} }
       />);
  }
}